// extracted by mini-css-extract-plugin
export var backgroundAnimation = "user-order-preview-module--backgroundAnimation--facc0";
export var column = "user-order-preview-module--column--7c763";
export var columnTitle = "user-order-preview-module--column-title--9628b";
export var columnValue = "user-order-preview-module--column-value--473cf";
export var columns = "user-order-preview-module--columns--f84a6";
export var container = "user-order-preview-module--container--64149";
export var divider = "user-order-preview-module--divider--41058";
export var grid = "user-order-preview-module--grid--dd0e0";
export var orderTitle = "user-order-preview-module--order-title--59374";
export var radiusBox = "user-order-preview-module--radius-box--b456d";
export var sectionBox = "user-order-preview-module--section-box--6ac22";
export var summary = "user-order-preview-module--summary--af25f";
export var wrapper = "user-order-preview-module--wrapper--36696";